/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/tailwind.css"
// import "prism-themes/themes/prism-atom-dark.css"
require("prismjs/themes/prism-tomorrow.css")
